<template>
<div class="bg-purple-100">
     <h1 class="px-2 mt-4 text-xl font-sans font-semi-bold bg-purple-300 rounded-t-lg">Cara Bayar</h1>
    <div class="mt-4 px-4 py-4">
      <p class="font-medium text-center">Tidak perlu khawatir soal pembayaran, kami memiliki beberapa metode pembayaran, dan pembayaran juga dilakukan dengan 2 kali Termin</p>
        <a class="relative md:px-4 md:py-4 mx-12 block  md:bg-gray-100 rounded-2xl md:flex md:flex-row md:justify-center md:my-2 md:gap-x-12 my-4" href="/#">
            <img src="@/assets/images/logo/bca.png" alt="" class="w-40 md:flex-row transition duration-500 ease-in-out transform hover:-translate-y-2">
            <img src="@/assets/images/logo/bri.png" alt="" class="w-32 md:flex-row transition duration-500 ease-in-out transform hover:-translate-y-2">
            <img src="@/assets/images/logo/link.png" alt="" class="w-40 md:flex-row transition duration-500 ease-in-out transform hover:-translate-y-2">
        </a>
    </div>
</div>
</template>
<script>
  export default {
    el: '#post',
    name : 'FAQ',
    data(){
    return{
                web:'',
    }
  },
    components:{
  },
    mounted() {
  },
    computed: {
  },
    methods:{

    }
}
</script>
